
import { createMuiTheme } from '@material-ui/core';
export default createMuiTheme({
	palette: {
		primary: {
			main: '#184B24',
		},
		secondary: {
			main: '#02211E',
	    },
	},
	typography: {
		useNextVariants: true,
		h1 : {
			fontFamily: '"Lacquer", sans-serif',
			fontSize: 75
		},
		h2 : {
			fontFamily: '"Lacquer", sans-serif',
		},
		h3 : {
			fontFamily: '"Lacquer", sans-serif',
		},
		h4 : {
			fontFamily: '"Lacquer", sans-serif',
		},
		h5 : {
			fontFamily: '"Lacquer", sans-serif',
		},
		h6 : {
			fontFamily: '"Lacquer", sans-serif',
		},
	},
	mixins: {
		horizontalCenter: {
			position: 'absolute',
			left: '50%',
			transform: 'translate(-50% , 0)'
		}
	}

});

