import React from 'react';

import { Switch, Route, withRouter} from "react-router-dom";
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import mach5theme from '../utils/app_theme';

import AppRoutes from '../routes/';
import AppHeader from '../components/AppHeader';
import AppNav from '../components/AppNav'

import HomeView from '../containers/Home'
import PhotosView from '../containers/Photos'
import VideosView from '../containers/Videos'
import PodcastsView from '../containers/Podcasts'
import PodcastPlayer from '../components/PodcastPlayer';

import 'react-image-lightbox/style.css';
import Oops404 from '../containers/404';
import classNames from 'classnames';



class AppShell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      top: false, 
      left: false,
      bottom: false,
      right: false,
      openPlayer: false,
      playerSrc: "",
      title: "",
    };
    this.togglePlayer = this.togglePlayer.bind(this);
    this.storeAppData = this.storeAppData.bind(this);
  }
 
  componentWillMount() {
    this.unlisten = this.props.history.listen( (location, action) => {
    
      this.setState({
        pathname: location.pathname,
        left: false
      });
    
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }

  togglePlayer(opts){
    
    if (opts.src === this.state.playerSrc){
      return false
    }
    if (opts.src === '' && this.props.history.location.pathname.indexOf("podcasts") !== -1 ){
      this.props.history.push('/podcasts')
    }
    this.setState({
      openPlayer: opts.open,
      playerSrc: opts.src,
      title: opts.title,
    })

  }
  storeAppData( data ){ 
    
    this.setState({  ...this.state, ...data })
    
  }
  render() {
    document.body.style.backgroundColor="#184B24"
    return (
        <MuiThemeProvider theme={mach5theme}>
          <CssBaseline />
          <AppHeader title={this.state.title} open={this.state.open} toggleDrawer={( side,open,index ) => {
            
            this.setState({ 
              [side]: open
            });

          }}  />
          <AppNav pathname={this.state.pathname} open={this.state.left} routes={AppRoutes}
          closeDrawer={()=>{
            this.setState({'left':false});
          }}
           /> 
          <main style={{marginTop: 100, paddingBottom: 40}}>
            <Switch>
              <Route
                  path="/"
                  exact={true}
                  component={HomeView}
              />
              <Route
                  path="/photos"
                  component={PhotosView}
              />
              <Route
                  path="/videos"
                  component={VideosView}
              />
              <Route
                path="/podcasts"
                exact={true}
                render={(props) => 
                  <PodcastsView {...props} 
                    togglePlayer={this.togglePlayer} 
                    podcasts={this.state.podcasts} 
                    storeAppData={this.storeAppData} 
                  />
                }
              />
              <Route
                path="/podcasts/:title"
                exact={true}
                render={(props) => 
                  <PodcastsView {...props} 
                    togglePlayer={this.togglePlayer} 
                    podcasts={this.state.podcasts} 
                    storeAppData={this.storeAppData} 
                  />}
              />
             
              
              <Route
                key="404Page"
                component={Oops404}
              />
            </Switch>
          </main>
          {Boolean(this.state.playerSrc) && (
            <>
              <div style={{ minHeight: 160 }} />
              <PodcastPlayer
                open={this.state.openPlayer}
                title={this.state.title}
                src={this.state.playerSrc}
                togglePlayer={this.togglePlayer}
              />
            </>
          )}
        </MuiThemeProvider>
    );
  }
}

export default withRouter(AppShell)