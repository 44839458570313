import React, {Component} from 'react'
import {Container} from '../styled/Profile'

class Videos extends Component {

  render() {
    return (
      <Container>
        <p>Videos</p>
      </Container>
    )
  }
}

export default Videos
