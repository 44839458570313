import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Link } from "react-router-dom";
import {ListItem, ListItemIcon, ListItemText,} from '@material-ui/core/';

const styles = theme => ({
  listRoot: {
    color: '#fff',
    backgroundImage:'url(spraypaint.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '0',
    '&$selectedItem': {
      backgroundColor: '#fff',
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      backgroundSize: '440px',
      backgroundPosition: '-132px -22px',
      '& $listItemLink':{
        color:theme.palette.secondary.main,
        fontWeight:'bold'
      }
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: theme.palette.secondary.main,
      backgroundImage:'url(spraypaint.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '502px',
      backgroundPosition: '-275px -33px',
      '& $icon':{
          color: theme.palette.secondary.main,
          fontWeight:'bold'
      },
      '& $listItemLink':{
        color:theme.palette.secondary.main,
        fontWeight:'bold'
      },
        
      '&$selectedItem': {
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        fontStyle: 'italic',
        backgroundSize: '440px',
        backgroundPosition: '-130px -22px',
        
        '& $icon':{
            color: '#fff',
            fontWeight:'bold'
        },
        '& $listItemLink':{
          color:theme.palette.secondary.main,
          fontWeight:'bold'
        },

      },

    },
  },
  listItem: {color:'#fff'},
  listItemLink:{
    color: '#fff',
    fontWeight:'normal',
    
    position: 'relative',
   

  },
  selectedItem:{},
  icon:{
    color:'#fff'
  }
});

class MainListItems extends Component{
  
  render(){
   
    const {classes} = this.props;
    return (
 
      <div>
        {this.props.routes.map((route, index) => (
            <ListItem 
            classes={{
              root: classes.listRoot,
              selected: classes.selectedItem
            }}
            selected={ this.props.pathname === route.path }
            button key={`${route+'_'+index}`} component={Link} to={route.path} >
              <ListItemIcon className={classes.icon}>
                {route.icon}
              </ListItemIcon>
              <ListItemText 
                classes={{
                  primary: classes.listItemLink
                }}
               primary={route.title} 
              />
            </ListItem>
          
        ))}
       
      </div>
    );
  }
  shouldComponentUpdate(nextProps){

    if(nextProps.pathname !== this.props.pathname){ 
      return true
    }

    return false
  }
}

export default withStyles(styles)(MainListItems);