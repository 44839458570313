import React from 'react'
import HomeIcon from '@material-ui/icons/Home';
import PhotosIcon from '@material-ui/icons/PhotoLibrary';
import PodcastsIcon from '@material-ui/icons/Mic';
import PodcastsView from '../containers/Podcasts'

import HomeView from '../containers/Home'
import PhotosView from '../containers/Photos'

const routes = [
  {
    path: "/",
    exact: true,
    main: HomeView,
    title: "Welcome",
    icon: <HomeIcon />
  },
  {
    path: "/photos",
    main: PhotosView,
    title: "Photo/Video",
    icon: <PhotosIcon />
  },
  {
    path: "/podcasts",
    main: PodcastsView,
    title: "Podcasts",
    icon: <PodcastsIcon />
  }
];

export default routes