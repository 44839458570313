import React, {Component} from 'react'
import {Paper} from '@material-ui/core/';
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
  FS_wrapper: {
    position: 'absolute',
    top: 70,
    left: 0,
    right: 0,
    minHeight: '100vh',
    backgroundColor: '#000',
    backgroundAttachment: 'fixed',
    backgroundPosition: '50% 100%', 
    backgroundSize: 'cover',
    backgroundImage: 'url(bg4.jpg)',
    backgroundRepeat: 'no-repeat'
  },
  homeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      '&:first-of-type': {
        ...theme.mixins.horizontalCenter,
        width: '100%',
        position: 'fixed',
      },
      zIndex: 1,
      position: 'relative',
      width: '75%',
      margin: 'auto',
      padding: '0 25px',
      maxWidth: 980,
      '& > *': {
        backgroundColor: 'rgba(255,255,255, 0.85)',
        display: 'inline-flex',
        color: '#184B24',
        marginBottom: 35,
        padding: '0 25px',
        borderRadius: 2,
        '&[href]:hover': {
          backgroundColor: theme.palette.primary.main,
          color: 'rgba(255,255,255, 0.85)',
          textShadow: `-1px 2px 1px ${theme.palette.primary.main}`,
        }
      },
      '& a': {
        alignSelf: 'center',
        textAlign: 'center',
        textDecoration: 'none',
        [theme.breakpoints.up('md')]: {
          alignSelf: 'flex-start',
        },
        textShadow: '-1px 2px 1px white',
        '&:first-of-type': {
          marginTop: 50,
          transform: 'rotate(2deg)'
        },
        '&:nth-of-type(even)': {
          [theme.breakpoints.up('md')]: {
            alignSelf: 'flex-end',
          },
        },
        '&:nth-of-type(2)': {
          transform: 'rotate(-1deg)'
        },
        '&:nth-of-type(3)': {
          transform: 'rotate(1deg)'
        },
        '&:nth-of-type(4)': {
          transform: 'rotate(0deg)',
          transition: 'all .375s ease-in-out',
          '&:hover': {
            transform: 'rotate(360deg)'
          },
        },
        '&:nth-of-type(5)': {
          transform: 'rotate(2deg)'
        },
        '&:nth-of-type(6)': {
          transform: 'rotate(-3deg)'
        },
        '&:hover': {
          transform: 'rotate(0deg)'
        },
      }
    }
  },
  mri: {
    backgroundImage: 'url(brain.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '175% 100%',
    minHeight: '100vh',
    backgroundPosition: '50% 60%',
    pointerEvents: 'none',
    width: '100%',
    top: 0,
    opacity: 0.6,
    [theme.breakpoints.up('md')]: {
      backgroundSize: '100% 100%',
      minHeight: '100vh',
      backgroundPosition: '50% 50%',    
     },
  },
})

class Home extends Component {
  render() {
     const {classes} = this.props;
    document.body.style.backgroundColor = "black";
    
    return (
      <Paper className={classes.FS_wrapper}>
        <div className={classes.homeWrapper}>
          <div className={classes.mri}/>
          <div>
            <Typography variant="h1" component="a" href="/podcasts">Podcasts</Typography><br />
            <Typography variant="h1" component="a" href="/photos">Photo/Video</Typography><br />
            <Typography variant="h1" component="a">A Grapefruit</Typography><br />
            <Typography variant="h1" component="a">the NEW TRACK</Typography><br />
            <Typography variant="h1" component="a">East Los</Typography>
          </div>
        </div>
      </Paper>
    )
  }
}


export default withStyles(styles)(Home);