import React, {Component} from 'react'

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { Link } from "react-router-dom";

const styles = theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },


  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fff',
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    position: 'absolute',
    left: 10,
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: 'transparent',
    '&:after':{
      position: 'absolute',
      top: -12,
      right: -25,
      bottom: -12,
      left: -25,
      backgroundImage:'url(spraypaint.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '556px',
      backgroundPosition: '-389px -179px',
      content:'""',
      zIndex: -1,
      transition: 'all .175s ease',
      transformOrigin: 'center',
      backfaceVisibility: 'hidden'
    },
    '&:hover':{
      color: '#fff',
      backgroundColor: 'transparent',
      '&:after': {
        backgroundSize: '600px',
        backgroundPosition: '-422px -195px',
      }
    }
  },
  menuButtonHidden: {
    display: 'none',
  },
  logo: {
    flexGrow: 1,
    textIndent: '-9999999px',
    width: '210px',
    height: '45px',
    margin: '12px -12px 12px auto',
    backgroundColor:'#fff',
    backgroundImage: 'url(m5.jpg)',
    backgroundPosition: '0 -161px',
    backgroundSize:'202px',
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: '3px',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    textAlign: 'center',
    '&:after':{
      position: 'absolute',
      top: -12,
      right: -25,
      bottom: -12,
      left: -25,
      backgroundImage:'url(spraypaint.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '556px',
      backgroundPosition: '-266px -33px',
      content:'""',
      zIndex: -1,
      transition: 'all .2s ease',
      transformOrigin: 'center',
      backfaceVisibility: 'hidden'
    },
    '&:hover':{
      '&:after':{
        transform: 'rotate(-1deg)',
        right: -26
      }
    }
  },
  
});


const drawerWidth = 240;


class AppHeader extends Component {
    
  
    render() {
      const { classes } = this.props;

      return (
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, this.props.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.props.open} className={classes.toolbar}>
            <IconButton
             aria-label="Open drawer"
              onClick={() => {
                this.props.toggleDrawer('left', true)
              }}
              className={classNames(
                classes.menuButton,
                this.props.open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
              <Link to="/" style={{textDecoration: 'none'}}>
                <h1 className={classes.logo}>Mach5Media {this.props.title}</h1>
              </Link>
            
          </Toolbar>
        </AppBar>
      )
    }
}
AppHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(AppHeader);