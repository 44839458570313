import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import _isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import slugify from '../utils/slugify';
import getRSSFeedItems from '../utils/getRSSFeedItems';
import sanitizeHTML from '../utils/sanitizeHTML';
import getReadableTime from '../utils/getReadableTime';
import moment from 'moment';

const podcastPageStyles = makeStyles(() => ({
  podcastsSection: {
    display: 'flex',
    gap: '24px',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
  }
}));
const podcastCardStyles = makeStyles(theme => {
  return ({
    card: {
      width: 300,
      boxShadow: 'none',
      minHeight: 385,
    },
    isActive: {},
    linkWrapper: {
      textDecoration: 'none',
      position: 'relative',
      '&:hover, &.isActive': {
        '& $playButton': {
          transform: 'translate(-100px,0)',
          backgroundColor: '#fff',
          transition: 'all .35s ease',
        },
        '& $playIcon': {
          color: theme.palette.primary.main,
        },
        '& $cover:before':{
          opacity: 1,
          transform: 'translate(0, -50%) scale(1,1)',
          transition: 'all .275s .15s ease',
        },
        '& $description':{
          transform: 'translate(0, -50%)',
          transition: 'all .4s .1s ease'
        },
        '& $title':{
          color: theme.palette.primary.main,
          textDecoration: 'underline',
          '&:before': {
            textDecoration: 'none!important',
          },
          '& span':{
            color: theme.palette.primary.main,
          }
        }
      }
    },
    cover: {
      height: 250,
      margin: 12,
      position: 'relative',
      borderRadius: '4px 4px 0 0',
      '&:before':{
        content: '""',
        backgroundColor: 'rgba(1,11,10, 0.8)',
        position: 'absolute',
        left: 0,
        right: 0,
        height: 125,
        top: '50%',
        transform: 'translate(0, -50%) scale(1,0)',
        textIndent: 400,
        transition: 'all .2s ease-out',
        fontSize: '40px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        opacity: 0,
  
      },
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0, 0)',
      transition: 'all .2s ease'
    },
    playButton:{
       color: '#fff',
      backgroundColor: 'rgba(24, 75, 36, 0.5)',
       backgroundImage:'url(spraypaint.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '602px',
        backgroundPosition: '-344px -33px',
      '&:hover':{
        color: '#fff',
        backgroundColor: theme.palette.secondary.main,
      },
      zIndex: 1,
      transition: 'all .2s ease'
    },
    playIcon: {
      height: 38,
      width: 38,  
    },
    
    description: {
      position: 'absolute',
      top: '50%',
      left: 95, 
      right: 10,
      transform: 'translate(500px, -50%)',
      transition: 'all .2s ease',
      color: '#fff',
      lineHeight: '1.25em',
      padding: 0,
      margin: 0,
      overflow: 'hidden',
      maxHeight: 100,
      display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
     '& p': {
       margin: '0 0 8px',
       '&:last-of-type': {
         marginBottom: 0
       }
     }
    },
    titleWrapper:{
      transition: 'all .5s ease',
      padding: '0 12px 12px!important',
      textAlign: 'center',
    },
    episodeDetails: {
      fontFamily: '"Roboto", sans-serif',
      fontWeight: 'normal',
      fontSize: 14,
      color: theme.palette.primary.light,
      margin: '8px 0 0',
      padding: 0,
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    title: {
      textTransform: 'capitalize',
      padding: 0,
      transition: 'color .5s ease',
      color: theme.palette.primary.main,
      fontFamily: '"Lacquer", sans-serif',
      fontSize: 26,
      margin: 0,
      lineHeight: '1.2em',
      '&:before':{
        display: 'block',
        textTransform: 'uppercase',
        content: 'attr(title)',
        fontSize: '0.65em',
        lineHeight: '1.5em',
        fontWeight: 'bold',
        letterSpacing: '-0.05rem',
        fontFamily: 'Arial',
        color: theme.palette.grey[500],
        textDecoration: 'none!important'
      },
      '& span':{
        fontStyle: 'italic',
        color: theme.palette.primary.main,
        fontSize: '0.9rem'
      },
    },
    duration: {
      padding: 0,
      fontSize: '0.85em',
      fontStyle: 'italic'
    },
    cardLoading: {
      '& $cover': {
        width: 300,
        backgroundColor: theme.palette.grey[300],
      },
      '& $title':{
        width: 200,
        backgroundColor: theme.palette.grey[300],
        height: 30,
        transform: 'translate(0, -5px)',
        '&:before':{
          content: '" "'
        },
      }
    }
  })
});
const PodcastCard = props => {
  const { podcast, placeHolderImage } = props;
  const classes = podcastCardStyles();
  return (
    <>
      {podcast.preloader ? (
        <Card className={classes.cardLoading} component="article">
            <CardMedia className={classes.cover}
              title="Loading"
              image="blank.png"
            >
            </CardMedia>
            <CardContent className={classes.titleWrapper}>
              <Typography component="h5" title={`Episode: ${podcast.episodeNumber}`} variant="body1" className={classes.title}>
                &nbsp;
              </Typography>
            </CardContent>
        </Card>
      ) : (
        <Card className={classes.card} component="article">
          <Link
            to={"/podcasts/"+slugify(podcast.title)}
            className={classNames(classes.linkWrapper, podcast.active && 'isActive')}
          >
            <CardMedia
              className={classes.cover}
              image={podcast.episodeImage || placeHolderImage}
              component="figure"
            >

              <div className={classes.controls}>
                <IconButton aria-label="Play/pause"
                  className={classes.playButton}
                >
                  <PlayArrowIcon className={classes.playIcon} />
                </IconButton>

                <Typography
                  paragraph
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html:podcast.description }}
                />
              </div>

            </CardMedia>
            <CardContent className={classes.titleWrapper} component="figcaption">
              <h4 className={classes.episodeDetails}>#{podcast.episodeNumber})&nbsp;{podcast.publishedDate}&nbsp;-&nbsp;{podcast.duration}</h4>
              <h1 className={classes.title}>{podcast.title}</h1>
            </CardContent>
          </Link>
        </Card>
      )}
    </>
  );
};
const  PodcastsPage = props => {
  const classes = podcastPageStyles();
  const [prevMatch, setPrevMatch] = useState();
  const [prevPodcasts, setPrevPodcasts] = useState();
  const [state, setState] = useState({
    podcasts: [],
    image: "https://s3-us-west-2.amazonaws.com/anchor-generated-image-bank/production/podcast_uploaded/1214952/1214952-1542865596028-5f26e62558b89.jpg" 
  });
  useEffect(() => {
    if (props.match && state.podcasts && state.podcasts.length) {
      if (!_isEqual(props.match, prevMatch) || !_isEqual(state.podcasts, prevPodcasts)) {
        setPrevMatch(props.match);
        setPrevPodcasts(state.podcasts);
        loadPodCast(props.match.params.title);
      }
    }
  }, [props.match, state.podcasts]);

  useEffect(() => {
    (async() => {
      try {
        const items = await getRSSFeedItems("https://anchor.fm/s/7d67520/podcast/rss");
        const podcasts = [];
        items.forEach((el, i) => {
          console.log({ fue: el.children[9].innerHTML });
          podcasts.push({
            episodeNumber: items.length - i,
            episodeImage: el.children[10].attributes.href.value,
            title: sanitizeHTML(el.querySelector("title").innerHTML),
            description: sanitizeHTML(el.querySelector("description").innerHTML),
            link: sanitizeHTML(el.querySelector('link').innerHTML),
            duration: getReadableTime(el.children[9].innerHTML),
            publishedDate: moment(el.querySelector('pubDate').innerHTML).format('ll')
          });
        });
        setState({ podcasts });
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const loadPodCast = title => {
    if (!title){
      return;
    }
    var podcast = state.podcasts.find(podcast => slugify(podcast.title) === title);
    if (podcast){ 
      props.togglePlayer({
        open: true,
        src: podcast.link,
        title: podcast.title
      });
      const nextPodcasts = state.podcasts.map(podcast => {
        return {
          ...podcast,
          active: Boolean(slugify(podcast.title) === title),
        };
      })
      setState({ ...state, podcasts: nextPodcasts });
    }
  }
  
  return (
    <section className={classes.podcastsSection}>
      {state.podcasts.map((podcast, index) => <PodcastCard podcast={podcast} placeHolderImage={state.image} index={index} key={`podcast-${podcast.episodeNumber}`} />)}
    </section>
  );
}

export default PodcastsPage;
