import React from 'react'
import { BrowserRouter as Router} from "react-router-dom";
import ReactDOM from 'react-dom'
import Mach5Media from './containers/Mach5Media'

ReactDOM.render(
	<Router>
	    <Mach5Media />
	</Router>,
  document.getElementById('root')
);