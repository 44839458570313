import React, {Component} from 'react'
import {Grid, Paper} from '@material-ui/core'
import {Link} from 'react-router-dom'

class Oops404 extends Component {

  render() {
    return (
      <Grid style={{width: '50%', margin: 'auto'}}>
      	<Paper style={{padding:25, margin: '25px auto' }}>
        	<h1>404 - Oops</h1>
        	<h3>Page not found.  <Link to="/">Go to Home Page</Link></h3>
        </Paper>
      </Grid>
    )
  }
}

export default Oops404
