import {media} from '../utils/media'
import styled from 'styled-components'

export const Container = styled.div`
 
`
export const ThemeContainer = styled.div`
  background-color: rgba(0,0,0, 0.85);
  width: 100%; 
  color: #fff; 
  padding: 20px;
  height: auto;
  margin: 0 auto 0;

  ${media.handheld`
    width: 90%;
    
  `}


`