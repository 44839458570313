const getRSSFeedItems = async url => {
  try {
    const rssResponse = await fetch(url);
    const responseText = await rssResponse.text();
    const data = await new window.DOMParser().parseFromString(responseText, "text/xml");
    return data.querySelectorAll("item");
  } catch (e) {
    console.error(e);
  }
};

export default getRSSFeedItems;