import React, {Component} from 'react'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';


const styles = theme => ({
  PodcastPlayer: {
    boxShadow: '0 -5px 10px rgba(0,0,0, 0.55)',
    backgroundColor: '#fff',
    backgroundImage:'url(spraypaint.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '600px',
    backgroundPosition: '145% 72%',
  },
  ToolbarRoot: {
    justifyContent: 'flex-start',
    border: 'none'
  },
  Iframe: {
    
    height: 102,
    
    [theme.breakpoints.up('md')]: {
     height: 160,
      minHeight: 0
    },  
 

  }
  
});


class PodcastPlayer extends Component {
    
    constructor( props ){
      super(props)
      this.closePlayer = this.closePlayer.bind(this)
    }
    closePlayer( ){
      this.props.togglePlayer({
        open: false,
        src: '',
        title: ''
      }); 
    }
    render() {
      const { classes, theme } = this.props;

      return (
       <Drawer anchor="bottom" variant="persistent" open={this.props.open} 
       classes={{
         paperAnchorBottom: classes.PodcastPlayer,
       }}

       >
          <Toolbar disableGutters={!this.props.openPlayer} className={this.ToolbarRoot}>

            <iframe title={this.props.title} src={`${this.props.src.replace('episodes', 'embed/episodes')}?a=1&auto=true&autoplay=1`} width="100%" scrolling="no" frameBorder="0" className={classes.Iframe}></iframe>           

            <IconButton onClick={this.closePlayer} style={{flex:'0 4 100%', justifyContent: 'center', borderRadius: 0, backgroundColor: 'transparent', color: '#fff'}}>
              <CancelIcon style={{fontSize: '1.5em', color: theme.palette.primary.main}} />
            </IconButton>
          </Toolbar>
       </Drawer>
      )
    }
}
PodcastPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


export default withStyles(styles, {withTheme: true})(PodcastPlayer)
