import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  photosPageWrapper: {
    margin: '50px 20px',
    paddingTop: 0,
    [theme.breakpoints.up('md')]: {
      margin: 50,
      paddingTop: 25,
    },
    '& [data-source=juicer]': {
      display: 'none!important'
    },
    '& .referral': {
      display: 'none!important'
    },
    '& .juicer-ad': {
      display: 'none!important'
    },
    '& .juicer-feed .j-meta a, & .juicer-feed.colors .j-meta a.j-social.j-instagram, & .juicer-feed.colored-icons .j-meta a.j-social.j-instagram': {
      color: 'rgb(24, 75, 36)!important',
    }
  },
  '@global': {
    '.j-post-overlay.juicer-feed .j-navigate a.j-previous, .j-post-overlay.juicer-feed .j-navigate a.j-next, .j-overlay .j-close, .j-post-overlay.juicer-feed a.juicer-button, .juicer-feed .j-meta a, .j-post-overlay.juicer-feed .j-overlay-text .j-message a, .j-post-overlay.juicer-feed .j-overlay-text .j-edit a': {
      color: 'rgb(24, 75, 36)!important',
    },
    '.j-post-overlay.juicer-feed a.juicer-button': {
      borderColor: 'rgb(24, 75, 36)!important',
      '&:hover': {
        backgroundColor: 'rgb(24, 75, 36)!important',
        color: 'white!important'
      }
    },
    '.j-overlay .j-close': {
      backgroundImage: 'none!important',
      '&:before': {
        position: 'absolute',
        top: -5,
        right: 0,
        content: '"x"',
        fontFamily: 'Arial',
        color: 'rgb(24, 75, 36)',
        transform: 'rotateX(40deg) scale(1.75)'
      }
    },
    '.juicer-feed.modern .j-poster h3': {
      fontFamily: '"Lacquer", sans-serif',
      fontSize: '26px!important',
      color: theme.palette.primary.main,
      textTransform: 'capitalize',
    }
  }
});

class Photos extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      expanded: false,
      instagram_posts: [],
      isOpen: false,
      photoIndex: 0,
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.photosPageWrapper}>
        <Grid 
          container 
          direction="row"
          justify="center"
          alignItems="center"
          spacing={24}
        >
          <ul class="juicer-feed" data-feed-id="mach5media" />
        </Grid>
      </div>

    )
  }
}

Photos.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Photos);
