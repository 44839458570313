import React, {Component} from 'react'

import { withStyles } from '@material-ui/core/styles';
import { Drawer, List } from '@material-ui/core/';
import MainListItems from '../styled/ListItems';

const styles = theme => ({
  listWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    width: 250,
    marginTop: 10,
    '& > div': {
      width: '100%'
    }
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '8px 0 15px rgba(0,0,0, 0.75)',
  },
  logoWrapper:{
    backgroundColor: '#fff',
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
  },
  logo:{
    textIndent: '-9999999px',
    width: '155px',
    height: '150px',
    margin: '12px auto',
    backgroundColor:'#fff',
    backgroundImage: 'url(m5.jpg)',
    backgroundPosition: '50%, 50%',
    backgroundSize:'contain',
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: '3px'
  },
  giphy: {
    width: '83%',
    height: 'auto',
    margin: '50px 0'
  }
});

class AppNav extends Component{
  componentDidUpdate(prevProps){
    if(prevProps.open === this.props.open){
      return null
    }
  }
  render(){
    const {classes} = this.props;
    const sideList = (
      <div className={classes.listWrapper}>
        <div 
          className={classes.list}>
          <List>
            <MainListItems pathname={this.props.pathname} routes={this.props.routes} />
          </List>
        </div>
        <img
          src="MM5GIF.gif"
          alt="Mach5Media Spy-vs-Spy"
          className={classes.giphy}
        />
      </div>
    );
    return (
      <Drawer 
       classes={{
         paperAnchorLeft: classes.drawerPaper,
       }}
       variant="temporary" 
       open={this.props.open}
       onClose={this.props.closeDrawer} >
        <div className={classes.logoWrapper}>
          <h1 className={classes.logo}>Click on a link below</h1>
        </div>
       <div
          tabIndex={0}
          role="button"
        >
          {sideList}
        </div>
         
      </Drawer>
    )
  }
    

} 
export default withStyles(styles)(AppNav);